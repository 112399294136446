import React from "react"
import Layout from "../../components/layout/Layout"
import SocialIcon from "../../components/social-icon/SocialIcon"
import "./Tiendas.css"
import maps from "../../images/maps.png"

export default () => {
  return (
    <Layout type="HMNF" className="stores" title="Tiendas">
      <div className="stores-content">
        <div className="stores-list">
          <div className="location">
            <span>Filtrar por ubicación:</span>
            <div className="location-control">
              <select name="location" id="location">
                <option value="lima">Lima</option>
                <option value="provincia">Provincia</option>
              </select>
            </div>
            <div className="stores-filter-list">
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
              <StoreCard />
            </div>
          </div>
        </div>
        <img className="stores-map" src={maps} alt="maps" />
      </div>
    </Layout>
  )
}

const StoreCard = () => {
  return (
    <div className="store-data">
      <h3 className="district">Angamos</h3>
      <div className="address">
        C.C. Angamos Open Plaza Tda. LC65. (4to piso)
      </div>
      <div className="phone">
        <span className="material-icons">phone</span> 713-1424{" "}
        <SocialIcon name="whatsapp" /> 998312028
      </div>
      <div className="schedule">Horario: 10:00am – 7:00pm</div>
      <div className="waze">
        <SocialIcon
          name="waze"
          url="https://www.waze.com/es/livemap?zoom=17&amp;lat=-12.11213&amp;lon=-77.01211"
        />
      </div>
    </div>
  )
}
